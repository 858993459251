import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import WebForm from '../components/webform.view';
import './embedded.styles.scss';
import { getQueryParameters } from '../utils/functions';

const API_URL = process.env.REACT_APP_API_URL;

export default function EmbeddedWebforms() {
  const [commId, setCommId] = useState('');
  const [joinCode, setJoinCode] = useState(null);
  const [isUrlValid, setIsURLValid] = useState(true);
  const [accountLogo, setAccountLogo] = useState('');

  const location = useLocation();
  const paramsToExclude = ['j', 'm'];
  const queryParams = getQueryParameters(location.search, paramsToExclude);

  let email = '';

  const modalType = {
    emailModal: 'emailModal',
    surveyQuestionsModal: 'surveyQuestionsModal',
  };

  /**
   * This function handles the creation of a participant once an iframe parent sends a message with the expected format
   * @param {MessageEvent<{data: { type: string, message: string, code: string}}>} event - Message Event sent by the iframe
   */
  function handleCreateParticipantMessage(event) {
    // if event is as expected, we set the commId as sent by the iframe parent
    if (event.data.type === 'webform:participant:add' && event.data.code === 'ADD_PARTICIPANT') {
      setCommId(event.data.message);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (!params.get('j')) {
      setIsURLValid(false);
    } else {
      setJoinCode(params.get('j'));
      setIsURLValid(true);

      // Only set commId if it has not been set prior
      if (!commId) {
        try {
          email = localStorage.getItem('p_j') || '';
          // Attempt to get any existing email from localStorage
          if (email) {
            setCommId(email);
          }
        } catch (error) {
          // do nothing if localStorage not available
        }
      }
      // We send this message to the parent of the iframe to notify them that the webform has loaded
      // They can reply to this message with participant details or any other supported action
      window.parent.postMessage(
        {
          type: 'webform:onload',
          message: 'webform loaded',
          code: 'WEBFORM_LOADED',
          modalType: commId || email ? modalType.surveyQuestionsModal : modalType.emailModal,
        },
        document.referrer || '*',
      );

      window.addEventListener('message', handleCreateParticipantMessage);

      axios
        .get(`${API_URL}/accounts/no-auth/web-form/${params.get('j')}`)
        .then((data) => {
          setAccountLogo(data.data.Data.logo);
        })
        // eslint-disable-next-line
        .catch((error) => console.error("Couldn't get account data : ", error));
    }

    return () => window.removeEventListener('message', handleCreateParticipantMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = formData.get('email');
    setCommId(email);

    try {
      localStorage.setItem('p_j', email);
    } catch (error) {
      // do nothing if localStorage not available
    }
  };

  return (
    <>
      <Helmet>
        <title>Ajua Webforms | Embedded</title>
      </Helmet>
      {isUrlValid ? (
        <>
          {commId ? (
            <WebForm joinCode={joinCode} commId={commId} userMetadata={queryParams} />
          ) : (
            <div className="container webform-wrapper">
              <div className="customer-logo">
                <img src={accountLogo} className="customer-account-avatar" alt="account logo"></img>
              </div>
              <form onSubmit={onSubmit} style={{ maxWidth: '800px', margin: '0 auto' }}>
                <p className="fw-300 mt-4">
                  To proceed with this survey, please provide us with your contact information.
                </p>
                <div className="row g-3 mt-3">
                  <div className="col">
                    <label htmlFor="ajWebformEmailInput" className="form-label mb-2 fw-300">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="ajWebformEmailInput"
                      name="email"
                      required
                      placeholder="juma@mail.com"
                    />
                  </div>
                </div>
                <div className="mt-1 mb-3">
                  <small className="small my-1" style={{ fontSize: 10 }}>
                    By providing your email, you agree to sharing your feedback guided by our{' '}
                    <a
                      href="https://ajua.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </small>
                </div>
                <button className="btn btn-primary kq-red mt-3" type="sumbit">
                  Proceed
                </button>
                <section className="powered-by-ajua">
                  <p className="small" style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Powered by </span>
                    <a href="https://ajua.com" target={'_blank'} rel="noopener noreferrer">
                      <img
                        src="/icons/icon-512x512.png"
                        className="ajua-logo-image"
                        alt="ajua logo"
                        style={{ height: '2rem' }}
                      />
                    </a>
                  </p>
                </section>
              </form>
            </div>
          )}
        </>
      ) : (
        <Navigate to="/404" />
      )}
    </>
  );
}
